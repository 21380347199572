html,body{
  height:100vh;
}

.container1{
  display:flex;
  flex-direction: column;
  height: 100vh;
  width:100%;
}

.app-header {
  background: green;
  height: 50px;
  width:100%;
}

.app-body {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.body-controller, .map-view {
  height: 100%;
  width:100%;  
}
.image-view{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}
.body-controller {
  display: flex;
  flex-direction: column;
}

.location-dropdown {
  width : 100% !important;
}

.cropped-image{
  width: auto;
  height: auto;
}

.result-image {
  display: block;
  width: 100px;
  height: 100px;
  margin-bottom: 30px;
}

.frame-to-capture {
  width: 100%;
  height: 100%;
  z-index: 6;
}

.map-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.component-frame {
  border-style: solid;
  border-width: 1px;
  border-color: #61dafb;
  padding: 3px;
  margin: 3px;
}

.control-pane-item {
  display: flex;
  flex-direction: row;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .popup {
  z-index: 9;
  background-color:transparent;
  border: 2px solid blue;
  text-align: center;
  height: 200px;
  width: 200px;
  min-height: 50px;
  min-width: 50px;
  max-height: 300px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-items: start;  
} */

/*Drgable */

.popup {
  /* position: absolute; */
  /*resize: both; !*enable this to css resize*! */
  /* overflow: auto; */
}

/* .popup-header {
  height: 25px;
  cursor: move;
  z-index: 10;
  background-color: #2196f3;
  color: #fff;
  font-size: xx-small;
} */

/*Resizeable*/

.popup .resizer-right {
  width: 5px;
  height: 100%;
  background: transparent;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: e-resize;
  z-index: 10;  
}

.popup .resizer-bottom {
  width: 100%;
  height: 5px;
  background: transparent;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: n-resize;
  z-index: 10;  
}

.popup .resizer-both {
  /* width: 10px;
  height: 10px;
  background: #2196f3;
  z-index: 11;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: nw-resize; */
}

/*NOSELECT*/

.popup * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.image-view {
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70));
}

.image-view img.overlay {
  mix-blend-mode: overlay;
}

.instructions::-webkit-scrollbar {
  display: none;
}